// import React from 'react'
import "./Content.css"
import Carousel from './components/Carousel.jsx'
// import HowDidWeDoIt from "../../assets/HowDidWeDoIt.png"

const Content = () => {

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="contentGeneralContainer">
            <Carousel/>
            {/* <img src={HowDidWeDoIt} alt="" className="howWeDoItImg"/> */}
            {/* <div className="preventiveSurveyContainer">
                <div className="preventiveSurveyTextContainer">
                    <h1 className="preventiveSurveyTitle">{"SafePlay Survey: Promoting Inclusion and Safety in the Football World"}</h1>
                    <p className="preventiveSurveyText">
                        GLOOUDS has developed a groundbreaking solution aimed at establishing an environment of respect, inclusion, and gender equality in the realm of sports. 
                        Through an anonymous survey module, individuals can identify and report instances of violence, abuse, and discrimination within sports clubs. 
                        This provides a secure platform for victims to share their experiences without fear, enabling club leaders to access this data to devise preventative strategies and awareness campaigns. 
                        The goal is to transform the world of football into a healthier and safer space for everyone.
                    </p>
                </div>
                <div className="preventiveSurveyImgContainer">
                    <img src={PreventiveSurveyStop} alt="Preventive Survey Img" className="preventiveSurveyImg"/>
                </div>
            </div> */}
        </div>
    );
};

export default Content;