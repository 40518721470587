import React from 'react'
import './Survey.css'
import SurveyImg from '../../assets/Survey/Survey.png'

const Survey = () => {
  return (
    <div className='surveyGeneralContainer'>

       <div className='surveyImgContainer'>
            <img src={SurveyImg} alt="Survey Image" className='surveyImg'/>
        </div>

        <h1 className='surveyTitle'> {"SafePlay Survey: Promoting Inclusion and Safety in the Football World"} </h1> 
        
        <p className='surveyText'> {`GLOOUDS has developed a groundbreaking solution aimed at establishing an environment of respect, inclusion, and gender equality in the realm
            of sports. Through an anonymous survey module, individuals can identify and report instances of violence, abuse, and discrimination within sports clubs. 
            This provides a secure platform for victims to share their experiences without fear, enabling club leaders to access this data to devise preventative strategies 
            and awareness campaigns. The goal is to transform the world of football into a healthier and safer space for everyone.`}
        <br/><br/>
        {`GLOOUDS strives to lead the global shift towards an inclusive and secure football environment. The solution seeks to enhance the quality of the football experience. 
        Cultural adaptability and sensitivity are key challenges, but GLOOUDS is committed to thoroughly understanding each context to create an effective solution. The global
        brand aims to influence clubs worldwide and foster significant change in football culture, celebrating diversity and creating an environment where everyone can thrive.`}
        <br/><br/>
        {`We've demonstrated that inclusion and respect are global concerns. GLOOUDS-affiliated clubs are already utilizing this solution to identify social issues within their 
        establishments and to plan awareness strategies.
        In the near future, this strategy will be widely adopted, contributing to a safer and more respectful football landscape. GLOOUDS aspires to shape a football world 
        where everyone can enjoy the game regardless of gender, promoting inclusion and eradicating violence and abuse.`} </p>
    </div>
  )
}

export default Survey