import "./VideoHome.css";

const VideoHome = () => {
  return (
    <div className="videoHome--container" style={{width:"75%", marginLeft:"12%", marginTop:"50px"}}>
      <iframe
        width={"100%"}
        height={"100%"}
        src= "https://www.youtube.com/embed/mXG6OLgC86c" // "https://www.youtube.com/embed/7PCV2XZYv3w" // "https://www.youtube.com/embed/NOlshNulh8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoHome;
