import "./App.css";
import "./global.styles.css";
import Layout from "./components/Layout/Layout";
import { Route, Routes, Navigate } from "react-router-dom";
import AppState from "./context/AppState";
// pages
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Licensing from "./pages/Licensing/Licensing";
import Oportunities from "./pages/Oportunities/Oportunities";
import Partners from "./pages/Partners/Partners";
import Legal from "./pages/Legal/Legal";
import Reports from "./pages/Reports/Reports";
import DialogContactUs from "./components/DialogContactUs/DialogContactUs";
import Survey from "./pages/Survey/Survey";
import MkpBanfield from "./pages/Marketplaces/MkpBanfield/MkpBanfield";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";

function App() {
  return (
    <AppState>
      <Layout>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/investor" element={<Licensing />} />
          <Route path="/oportunities" element={<Oportunities />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/#" element={<DialogContactUs />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/MkpBanfield" element={<MkpBanfield />} />
          <Route path="/DeleteAccount" element={<DeleteAccount />}></Route>
        </Routes>
      </Layout>
    </AppState>
  );
}

export default App;
