const CloseCyan = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.408 11.9963L19.708 5.70628C19.8963 5.51798 20.0021 5.26258 20.0021 4.99628C20.0021 4.72998 19.8963 4.47458 19.708 4.28628C19.5197 4.09798 19.2643 3.99219 18.998 3.99219C18.7317 3.99219 18.4763 4.09798 18.288 4.28628L11.998 10.5863L5.70799 4.28628C5.51968 4.09798 5.26429 3.99219 4.99799 3.99219C4.73168 3.99219 4.47629 4.09798 4.28799 4.28628C4.09968 4.47458 3.99389 4.72998 3.99389 4.99628C3.99389 5.26258 4.09968 5.51798 4.28799 5.70628L10.588 11.9963L4.28799 18.2863C4.19426 18.3792 4.11986 18.4898 4.06909 18.6117C4.01833 18.7336 3.99219 18.8643 3.99219 18.9963C3.99219 19.1283 4.01833 19.259 4.06909 19.3809C4.11986 19.5027 4.19426 19.6133 4.28799 19.7063C4.38095 19.8 4.49155 19.8744 4.61341 19.9252C4.73527 19.9759 4.86597 20.0021 4.99799 20.0021C5.13 20.0021 5.2607 19.9759 5.38256 19.9252C5.50442 19.8744 5.61502 19.8 5.70799 19.7063L11.998 13.4063L18.288 19.7063C18.3809 19.8 18.4915 19.8744 18.6134 19.9252C18.7353 19.9759 18.866 20.0021 18.998 20.0021C19.13 20.0021 19.2607 19.9759 19.3826 19.9252C19.5044 19.8744 19.615 19.8 19.708 19.7063C19.8017 19.6133 19.8761 19.5027 19.9269 19.3809C19.9776 19.259 20.0038 19.1283 20.0038 18.9963C20.0038 18.8643 19.9776 18.7336 19.9269 18.6117C19.8761 18.4898 19.8017 18.3792 19.708 18.2863L13.408 11.9963Z"
        fill="#00FFFF"
      />
    </svg>
  );
};

export default CloseCyan;
