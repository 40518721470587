import "./Banner.css";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
// images
// import bg_banner from "../../assets/BannerHome/bg_banner.png";
import GlooudsLogo from "../../assets/img/GlooudsLogo.png";
import header_text from "../../assets/BannerHome/header_text.png";
import app_store from "../../assets/Footer/app_store.png";
import google_play from "../../assets/Footer/google_play.png";
// components
// import ButtonSecondary from "../Buttons/ButtonSecondary";

const Banner = () => {
  const { handleClickOpen } = useContext(AppContext);

  return (
    <div className="coverImage">
      <div className='bannerFirstContainer'>
        <div className='bannerSecondContainer'>
          <div className='bannerThirdContainer'>
            
            <img src={GlooudsLogo} alt="" className='bannerHeaderLogo'/>
              <div className='bannerHeaderButtonsContainer'>
                
                <div className="bannerBtnAndImgContainer">
                  <button className='bannerAppleBtn'>
                    <a href="https://apps.apple.com/es/app/gloouds/id1633309773" target="_blank" rel="noreferrer">
                      <img src={app_store} alt="" className="imgbutton"/>
                    </a>
                  </button>
                  
                  <button className='bannerGoogleBtn'>
                    <a href="https://play.google.com/store/apps/details?id=com.gloouds" target="_blank" rel="noreferrer">
                      <img src={google_play} alt=""  className="imgbutton"/>
                    </a>
                  </button>
                </div>
              </div>

              <div className="bannerRequestDemoBtnContainer">
                <button 
                  className="bannerRequestDemoBtn"
                  onClick={() => handleClickOpen()}
                  label="Request your demo"
                  border="none"
                >
                  REQUEST YOUR DEMO
                </button>
              </div>
              
          </div>

          <div className='bannerHeaderTextImgContainer'>
            <img src={header_text} alt="" className="bannerHeaderTextImg"/>
          </div>

          <div className="bannerRequestDemoBtnContainerMob">
                <button className='bannerAppleBtn'>
                  <a href="https://apps.apple.com/es/app/gloouds/id1633309773" target="_blank" rel="noreferrer">
                    <img src={app_store} alt="" className="imgbutton"/>
                  </a>
                </button>
                
                <button className='bannerGoogleBtn'>
                  <a href="https://play.google.com/store/apps/details?id=com.gloouds" target="_blank" rel="noreferrer">
                    <img src={google_play} alt=""  className="imgbutton"/>
                  </a>
                </button>
              <button 
                className="bannerRequestDemoBtn"
                onClick={() => handleClickOpen()}
                label="Request your demo"
                border="none"
              >
                REQUEST YOUR DEMO
              </button>
          </div>
        </div>

        <div className="bannerLastTextContainer">
          
          <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>GLOOUDS </b>
          <b style={{color:"#ffffff", fontFamily:"var(--font700)"}}>Generate income for football in clubs, academies, leagues and federations.</b> <br></br>
          To achieve the economic and financial independence of grassroots or youth fotball to promote the development of talent in quantity and quality.
        </div>

      </div>
    </div>
  );
};

export default Banner;
