import "./HowWeDoIt.css"
import HowWeDoItImage from "../../assets/HowWeDoIt.png"


const HowWeDoIt = () => {
  
    return (
      <>
        <div className='hwdiTextAndImgContainer'>
            <div className="hwdiTextBox">
                <p className='hwdiText'>TWO STEPS MONETIZATION</p>
                <p className="hwdiText" style={{color:"#00ffff", fontFamily:"var(--font700)", borderBottom:'solid #00ffff 3px'}}>How we do it?</p>
                <p className="ambassadorsText">
                    <b className="hwdiNumber">1.</b>
                    <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> GLOOUDS - Conclutions - </b>
                    We offer an integral performance solution that generates talent efficiency in football, by giving conclutions contrasted with the 
                    <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Opportunity Map </b>
                    for sports and economics decition making
                </p>
                <p className="hwdiText">
                <b className="hwdiNumber">2.</b>
                    <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> FAN 2.0 – Contents –  </b>
                    Monetization is allowed through monthly subscriptions, 
                    providing exclusive access to club-generated content and offering the opportunity to collaborate
                </p>
            </div>
            <div  >
                <img src={HowWeDoItImage} alt="" className='hwdiImg'/>
            </div>

        </div>
    </>
    );
};
export default HowWeDoIt;