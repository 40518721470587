import axios from "axios";
import { buildUrlEncoded } from "../utils/data";

export const verifyUser = async (userName, password) => {
    const formData = {
        email: userName,
        password: password
    }
    const body = buildUrlEncoded(formData)
    try {
        const res = await axios.post("https://api.gloouds.com/api/login", body, {
            headers: {"Content-Type": "application/x-www-form-urlencoded",},});
        if (res.data.ok === true){ 
            return 'ok';
        } else {
            return "error"
        }
    } catch {
        return "error"
    }
}