import "./Reports.css";
import TitlePage from "../../components/TitlePage/TitlePage";

const cardsReports = [
  {
    id: "1",
    title: "1Q2023 Report",
    languaje: "Spanish",
    type:"pdf",
    content: "Informe para inversores correspondiente al 1Q del 2023",
    link:"https://gloouds-resource-v2.s3.amazonaws.com/Public/Reports/1Q2023+-+Investor+report.pdf"
  },
  {
    id: "2",
    title: "2Q2023 Report",
    languaje: "Spanish",
    type:"pdf",
    content: "Informe para inversores correspondiente al 2Q del 2023",
    link:"https://gloouds-resource-v2.s3.amazonaws.com/Public/Reports/2Q2023+-+Investor+report.pdf"
  }
];

const Legal = () => {
  return (
  <>
  <div>
    <TitlePage title="Investor reports"/>
    <div className="legal--description">
        <p>
        In this section you will find the quarterly reports for our investors where we show the results, progress, status and evolution of the project. 
        </p>
    </div>

    <div className="legales--container-box">
        {cardsReports.map((card) => {
          return (
            <div key={card.id} className="legales--box">
              <a href={card.link} target="_blank" rel="noreferrer">
              <h2>{card.title}</h2>
              <p>{card.content}</p>
              <div className="legales--subcard">
                <div><p>Languaje: {card.languaje}</p></div>
                <div><p>File Type: {card.type}</p></div>
              </div></a>
            </div>
          );
        })},
      </div>
  </div>
  </>
  );
};
export default Legal;