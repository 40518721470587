import cafu from "../../assets/Cafu.png";
import kempes from "../../assets/Kempes.png";
import "./Ambassadors.css"


const Ambassadors = () => {
  
    return (
      <>
        <div className='ambassadorsTextAndImgContainer'>
            <img src={cafu} alt="" className='ambassadorsImg'/>
            <p className='ambassadorsText'>
                The inclusion 
                of  <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>Cafú</b> & <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>Kempes</b> as  <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>GLOOUDS</b> Ambassadors
                 has a significant impact on both the brand and the company’s campaign, as well as on the promotion of the 
                values thay  <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>GLOOUDS</b> fosters through homegrown youth football. 
                With their remarkable trajectories and worldwide 
                recognition, <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>Cafú</b> &  <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>Kempes</b> embody
                 positive values and leadership, while strengthening the company’s image and credibity.
            </p>
            <img src={kempes} alt="" className='ambassadorsImg'/>

        </div>
    </>
    );
};
export default Ambassadors;