import "./About.css";
// components
import TitlePage from "../../components/TitlePage/TitlePage";
// images
import AboutImage from "../../assets/About/AboutImage.png";
import avatar_1 from "../../assets/About/1.diego.png";
import avatar_2 from "../../assets/About/2.mariano.png";
import avatar_4 from "../../assets/About/4.lucas.png";
import avatar_6 from "../../assets/About/6.pablo.png";
import avatar_7 from '../../assets/About/garau.png'
import avatar_8 from '../../assets/About/gil.png'
import avatar_9 from '../../assets/About/sevi.png'
import avatar_10 from '../../assets/About/campbell.png'
import avatar_11 from '../../assets/About/tabarez.png'
import avatar_12 from '../../assets/About/bueno.png'
import avatar_13 from '../../assets/About/duany.png'
import avatar_14 from '../../assets/About/chevalier.png'
import avatar_15 from '../../assets/About/quintero.png'
import avatar_16 from '../../assets/About/bordato.png'
import avatar_17 from '../../assets/About/urcola.png'
// import avatar_18 from '../../assets/About/santana.png'
import avatar_19 from '../../assets/About/zapata.png'
// import avatar_20 from '../../assets/About/lopez.png'
// import avatar_21 from '../../assets/About/traverso.png'

import linkedinlogo from "../../assets/About/linkedin.png";

const Board = [
  {
    name: "Diego Muller",
    img: avatar_1,
    description: "CEO & Founder",
    subdescription: "Chief Excecutive Officer",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/mullerdiego"
  },
  {
    name: "Mariano Robledo",
    img: avatar_2,
    description: "CTO & Founder",
    subdescription: "Chief Techonology Officier",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/mariano-robledo-2b336096"
  },
  {
    name: "Lucas Bracamonte",
    img: avatar_4,
    description: "CTOTB",
    subdescription: "Chief Data Technolgy Officer Tech Business",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/lucas-braca"
  },
  {
    name: "Pablo Borra",
    img: avatar_6,
    description: "CFO",
    subdescription: "Chief Financial Officer",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/pborra"
  }
];

const IandD = [
   {
    name: "Marcos Hernandez Gil",
    img: avatar_8,
    description: "CTDA",
    subdescription: "Researcher and expert in risk and biomedicine models",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/marcoshndezg"
  },
  {
    name: "Gabriel Garau",
    img: avatar_7,
    description: "C Football AA",
    subdescription: "Chief Researcher and expert in machine learning",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/gabrielgarauestarellas"
  },
  {
    name: "Facundo Tabarez",
    img: avatar_11,
    description: "CMS",
    subdescription: "Expert in methodologies and strategies applied to elite sport",
    linkedin: linkedinlogo,
    profile: ""
  },
  {
    name: "Sevi",
    img: avatar_9,
    description: "C Football AS",
    subdescription: "Expert Chief Scouting & Tactical Performance Analysis",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/miguel-sanchez-fernandez-sevi-42a706146/"
  },
  {
    name: "Duncan Campbell",
    img: avatar_10,
    description: "CTDAO",
    subdescription: "Expert in methodologies and strategies applied to elite sport",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/duncancampbell1/"
  },
];

const BD = [
  {
    name: "J. L. Martinez Bueno",
    img: avatar_12,
    description: "CM",
    subdescription: "Business Development Manager",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/jose-luis-martinez-bueno-5671a124/"
  },
  {
    name: "J. E. J. Duany",
    img: avatar_13,
    description: "CMWEB3",
    subdescription: "WEB3 Business Development",
    linkedin: linkedinlogo,
    profile: ""
  },
];

const TechDev = [
  {
    name: "Esteban Chevalier",
    img: avatar_14,
    description: "BE DEV",
    subdescription: "Back End Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/esteban-chevalier"
  },
  {
    name: "Daniela Quintero",
    img: avatar_15,
    description: "UI DEV",
    subdescription: "UI Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/daniela-quintero-ui/"
  },

  {
    name: "Jose Bordato",
    img: avatar_16,
    description: "MOB DEV",
    subdescription: "Mobile Develompent",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/bordato87"
  },
  {
    name: "Lucas Urcola",
    img: avatar_17,
    description: "BE DEV",
    subdescription: "Back End Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/lucas-gonzalo-urcola-33a99b19"
  },
  /* {
    name: "Leandro Santana",
    img: avatar_18,
    description: "FE DEV",
    subdescription: "Front End Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/leandro-cabral-santana-1a1805195/"
  }, */
  {
    name: "Constanza Zapata",
    img: avatar_19,
    description: "UX DEV",
    subdescription: "UX Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/constanza-zapata/"
  },
  /* {
    name: "Rocío Lopez",
    img: avatar_20,
    description: "UI DEV",
    subdescription: "UI Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/rocio-lopez-martinez-3b6218246"
  }, */
  /* {
    name: "Pedro Traverso",
    img: avatar_21,
    description: "FS DEV",
    subdescription: "Full Stack Development",
    linkedin: linkedinlogo,
    profile: "https://www.linkedin.com/in/pedrotraverso"
  }, */
];

const About = () => {
  return (
    <>
      <TitlePage title="About" />
      <div className="about--container-description-img">
        <span className="span about--span-0"></span>
        <div className="about--container-description">
          <h2> Mission </h2>
          <p>
          At GLOOUDS, our mission is to empower financial independence in grassroots football by generating income through innovative solutions backed by advanced technology.
          We work collaboratively with clubs and federations to strengthen the education, values, and opportunities of passionate young football enthusiasts. 
          Our focus spans both web2 and web3 platforms, enabling us to achieve a significant global impact.
          </p>
          <h2>Vision</h2>
          <p>
          At GLOOUDS, we envision a future where youth in football find empowerment through transparent and collaborative decision-making, enabled by DAO (Decentralized Autonomous Organization) technologies. 
          We strive to lead in this transformation by setting a new standard in grassroots football, where meritocracy and equitable participation are paramount. 
          Our vision is of a world where every young individual, regardless of their background, has the opportunity to turn their football dreams into reality, in an ecosystem that transcends borders and fosters robust and sustainable growth.
          </p>
        </div>
        <div className="about--container-img">
          <img src={AboutImage} alt="" />
        </div>
      </div>


      <div className="about--container-team">
        <h2>Board</h2>
        <span className="span about--span-1"></span>
        <div className="about--container-cards-team">
          {Board.map((avatar, i) => {
            return (
              <div key={i} className="about--cards-team">
                <img src={avatar.img} alt={avatar.name} />
                <h3>{avatar.name}</h3>
                {/* <h4> <a href={avatar.profile} target="_blank" rel="noreferrer noopener"> Linkedin</a> </h4> */}
                <div className="linkedinAndDescriptionContainer">
                  <img src={linkedinlogo} alt="" className="linkedinLogo" onClick={() => { window.open(avatar.profile) }} target="_blank"/>
                  <h4>{avatar.description}</h4>
                </div>
                {/* <p> {avatar.subdescription} </p> */}
              </div>
            );
          })}
        </div>
        <span className="span about--span-2"></span>
        <h2>I+D</h2>
        <span className="span about--span-1"></span>
        <div className="about--container-cards-team">
          {IandD.map((avatar, i) => {
            return (
              <div key={i} className="about--cards-team">
                <img src={avatar.img} alt={avatar.name} />
                <h3>{avatar.name}</h3>
                {/* <h4> <a href={avatar.profile} target="_blank" rel="noreferrer noopener"> Linkedin</a> </h4> */}
                <div className="linkedinAndDescriptionContainer">
                  <img src={linkedinlogo} alt="" className="linkedinLogo" onClick={() => { window.open(avatar.profile) }} target="_blank"/>
                  <h4>{avatar.description}</h4>
                </div>
               {/*  <p> {avatar.subdescription} </p> */}
              </div>
            );
          })}
        </div>
        
        
          <h2>Business Development</h2>
         
          <div className="about--container-cards-team">
            {BD.map((avatar, i) => {
              return (
                <div key={i} className="about--cards-team">
                  <img src={avatar.img} alt={avatar.name} />
                  <h3>{avatar.name}</h3>
                  {/* <h4> <a href={avatar.profile} target="_blank" rel="noreferrer noopener"> Linkedin</a> </h4> */}
                  <div className="linkedinAndDescriptionContainer">
                    <img src={linkedinlogo} alt="" className="linkedinLogo" onClick={() => { window.open(avatar.profile) }} target="_blank"/>
                    <h4>{avatar.description}</h4>
                  </div>
                  {/* <p> {avatar.subdescription} </p> */}
                </div>
              );
            })} 
          </div>
        
          
          

      
            <h2>Tech Development</h2>
          
          <div className="about--container-cards-team">
            {TechDev.map((avatar, i) => {
              return (
                <div key={i} className="about--cards-team">
                  <img src={avatar.img} alt={avatar.name} />
                  <h3>{avatar.name}</h3>
                  {/* <h4> <a href={avatar.profile} target="_blank" rel="noreferrer noopener"> Linkedin</a> </h4> */}
                  <div className="linkedinAndDescriptionContainer">
                    <img src={linkedinlogo} alt="" className="linkedinLogo" onClick={() => { window.open(avatar.profile) }} target="_blank"/>
                    <h4>{avatar.description}</h4>
                  </div>
                 {/*  <p> {avatar.subdescription} </p> */}
                </div>
              );
            })}
          </div>
        
          
          
          
        
      </div>
    </>
  );
};
export default About;
