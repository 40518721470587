import logo from "../../../assets/header_logo.png"
import banfield from "../../../assets/banfield.png"
import "../Styles/Mkp.styles.css"

const MkpBanfield = () => {
    return(
        <>
            <div style={{color:"white", display:"flex", justifyContent:"space-between", width:"90%", padding:"0 2rem 0 2rem", alignItems:'center'}}>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={logo} alt="Gloouds" className='escudos' style={{width:'100%', height:'100%', objectFit: 'contain'}}></img>
                </div>
                
                <div style={{fontSize:'40px', fontWeight:'600'}}>Marketplace NFT Banfield</div>

                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
            </div>
            <div style={{color:"white", display:"flex", justifyContent:"space-between", width:"90%", padding:"0 2rem 0 2rem", alignItems:'center', margin:'5rem 0 10rem 0'}}>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
                <div style={{ width: '8rem', height: '8rem'}}>
                    <img src={banfield} alt="Banfield" style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                </div>
            </div>
        </>
        
    )

}

export default MkpBanfield