import { useState, useContext } from "react";
import AppContext from "../../context/AppContext";
import { sendEmail } from "../../services/email";

import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ButtonDialog from "./ButtonDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import whatsappIcon from "../../assets/whatsapp.png";
import email from "../../assets/email.png";

import "./DialogContactUs.css";
import { sendWapp } from "../../services/whatsapp";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiDialog-paper": {
      padding: "20px 10px",
      borderRadius: "0px",
    },
  },
  title: {
    fontSize: "20px",
    lineHeight: "23px",
    fontFamily: "Hind Siliguri Medium",
  },
  input: {
    color: "red",
    "& .MuiInputBase-root": {
      marginBottom: "30px",
    },
  },
  icon: {
    width: '80px',
    height: '80px',
    display: 'block',
    cursor: 'pointer',
  },
  icon2: {
    width: '60px',
    height: '60px',
    display: 'block',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 18,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Hind Siliguri Medium"],
    "&:focus": {
      boxShadow: ` #8D8D8D 0 0 0 0.1rem`,
      borderColor: "#8D8D8D",
    },
  },
  "& .MuiFormLabel-root-MuiInputLabel-root": {
    color: "#8D8D8D",
    "&:focus": {
      boxShadow: ` #8D8D8D 0 0 0 0.1rem`,
      borderColor: "#8D8D8D",
    },
  },
}));

const DialogContactUs = () => {
  const classes = useStyles();

  // const initialState = {
  //   subject: "Nuevo contacto desde la web de Gloouds",
  //   to: "hola@gloouds.com",
  //   template: "newContact",
  //   lenguage: "us",
  //   name: "",
  //   message: "",
  //   email: "",
  //   phone: "",
  // }

  const initialState = {
    subject: "Nuevo contacto desde la web de Gloouds",
    to: "hola@gloouds.com",
    template: "newContact",
    lenguage: "es",
    name: "",
    email: "",
    phone: "",
    message: "",
    club: "",
    position: ""
  }
  
  const [form, setForm] = useState(initialState);
  const [inputErrorCheck, setInputErrorCheck] = useState({});

  const { open, handleClose } = useContext(AppContext);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const reset = () => {
    setForm(initialState);
  };
  
  const onSubmit = (e) => {
    e.preventDefault()

    if(Object.values(form).every(value => value !== "")){  
      sendEmail(form);
      
      setTimeout(() => {
        reset();
        handleClose();
        setInputErrorCheck({});
      }, 500)
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-DialogContactUs"
      aria-describedby="alert-dialog-DialogContactUs"
      className={classes.container}
    >
      <IconButton
        aria-label="close"
        className="modal-close-button"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "right", alignSelf: "self-end" }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="DialogContactUs--title" className={classes.title}>
        {
          "Leave us your details and shortly a Gloouds representative will contact you to coordinate a demo will contact you to coordinate a product demo."
        }
      </DialogTitle>
      <form onSubmit={(e) => onSubmit(e)}>
        <DialogContent>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <FormControl variant="standard" className={classes.input}>
              <InputLabel
                shrink
                htmlFor="name-input"
                inputlabelprops={{
                  className: classes.input,
                }}
              >
                Name and surname
              </InputLabel>
              <BootstrapInput
                placeholder="Example: Juan Perez"
                id="name-input"
                name="name"
                onChange={(e) => handleChange(e)}
                value={form.name}
                style={{ marginBottom: inputErrorCheck.name === "" ? '-10px' : null}} 
                />
                {inputErrorCheck.name === "" ? <p className="errorMessage">Please write your name and surname</p> : null}
            </FormControl>
            <FormControl variant="standard" className={classes.input}>
              <InputLabel shrink htmlFor="email-input">
                Email
              </InputLabel>
              <BootstrapInput
                placeholder="Example: Juanperez@gmail.com"
                id="email-input"
                name="email"
                onChange={(e) => handleChange(e)}
                value={form.email}
                style={{ marginBottom: inputErrorCheck.email === "" ? '-10px' : null}} 
              />
              {inputErrorCheck.email === "" ? <p className="errorMessage">Please write your email</p> : null}
            </FormControl>
            <FormControl variant="standard" className={classes.input}>
              <InputLabel shrink htmlFor="phone-input">
                Contact telephone
              </InputLabel>
              <BootstrapInput
                placeholder="+ Country code + Area code + Phone number"
                id="phone-input"
                name="phone"
                onChange={(e) => handleChange(e)}
                value={form.phone}
                style={{ marginBottom: inputErrorCheck.phone === "" ? '-10px' : null}} 
                />
                {inputErrorCheck.phone === "" ? <p className="errorMessage">Please write your telephone number</p> : null}
            </FormControl>

            <FormControl variant="standard" className={classes.input}>
              <InputLabel shrink htmlFor="club-input">
                Club
              </InputLabel>
              <BootstrapInput
                placeholder="Please write the name of your club"
                id="club-input"
                name="club"
                onChange={(e) => handleChange(e)}
                value={form.club}
                />
            </FormControl>

            <FormControl variant="standard" className={classes.input}>
              <InputLabel shrink htmlFor="position-input">
                Position
              </InputLabel>
              <BootstrapInput
                placeholder="Please write the name of the position you hold"
                id="position-input"
                name="position"
                onChange={(e) => handleChange(e)}
                value={form.position}
                />
            </FormControl>

            <FormControl variant="standard" className={classes.input}>
              <InputLabel shrink htmlFor="message-input">
                Leave us your comments
              </InputLabel>
              <BootstrapInput
                placeholder="Comments..."
                id="message-input"
                name="message"
                onChange={(e) => handleChange(e)}
                value={form.message}
                style={{ marginBottom: inputErrorCheck.message === "" ? '-10px' : null}} 
              />
              {inputErrorCheck.message === "" ? <p className="errorMessage">Please write the comments you want to send us</p> : null}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "10px 25px" }}>

          <div style={{display: 'flex', alignItems:'center', cursor:'pointer'}} onClick={() => sendWapp(form)}>
            <div style={{fontSize: '12px', fontWeight:'600'}}>Send via whatsapp</div>
            <div className={classes.icon}>
              <img src={whatsappIcon} alt="whatsapp" className={classes.image} />
            </div>
          </div>
          <button type="submit" style={{display: 'flex', alignItems:'center', cursor:'pointer', border: 'none', backgroundColor:'transparent'}} onClick={() => setInputErrorCheck(form)}>
            <div style={{fontSize: '12px', fontWeight:'600'}}>Send via email</div>
            <div className={classes.icon2}>
              <img src={email} alt="email" className={classes.image} />
            </div>
          </button>
          {/* <ButtonDialog label="Send" onClick={() => setInputErrorCheck(form)}/> */}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogContactUs;
