import React, { useRef } from 'react'
// CSS
import '../Content.css'
// SLIDER
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// IMGS
import WhatWeDo from '../../../assets/img/WhatWeDo.png'
import HowDidWeDoIt from '../../../assets/img/HowDidWeDoIt.png'
import ValueOfTechnologies from '../../../assets/img/ValueOfTechnologies.png'
import GlooudsTools from '../../../assets/img/GlooudsTools.png'
import OpportunityMap from '../../../assets/img/OpportunityMap.png'
import Fan20 from '../../../assets/img/Fan20.png'
import DigitalAssets from '../../../assets/img/DigitalAssets.png'
import GlooudsMetaverse from '../../../assets/img/GlooudsMetaverse.png'
import FIFAGloouds from '../../../assets/img/FIFAGloouds.png'
// import Fan20Example from '../../../assets/img/Fan20Example.png'
// import GlooudsPatronageExample from '../../../assets/img/GlooudsPatronageExample.png'
// import GlooudsMetaverseExample from '../../../assets/img/GlooudsMetaverseExample.png'
// import GlooudsMonetizationExample from '../../../assets/img/GlooudsMonetizationExample.png'
import GlooudsTeamBuilder from '../../../assets/img/GlooudsTeamBuilder.png'
// import GlooudsMarketPotential from '../../../assets/img/GlooudsMarketPotential.png'
import GlooudsUtilityToken from '../../../assets/img/GlooudsUtilityToken.png'
// import GlooudsCurrentFunding from '../../../assets/img/GlooudsCurrentFunding.png'
// import GlooudsPyL from '../../../assets/img/GlooudsPyL.png'
// import GlooudsUseOfFunds from '../../../assets/img/GlooudsUseOfFunds.png'
// import GlooudsPotentialValuation from '../../../assets/img/GlooudsPotentialValuation.png'
// import GlooudsCurrentOwnership from '../../../assets/img/GlooudsCurrentOwnership.png'
// import GlooudsTeam from '../../../assets/img/GlooudsTeam.png'

// import GlooudsPartners from '../../../assets/img/GlooudsPartners.png'
import GlooudsPartners from '../../../assets/img/GlooudsPartners.jpeg'

const Carousel = () => {

    const buttonNames = [
        { name: "PERFORMANCE", id: 1 },
        { name: "OPORTUNITY MAP", id: 2 },
        { name: "FAN 2.0", id: 3 },
        { name: "DIGITAL ASSETS", id: 4 },
        { name: "METAVERSE", id: 5 }
    ]

    const carouselImages = [
        { img: WhatWeDo },
        { img: HowDidWeDoIt },
        { img: ValueOfTechnologies },
        { img: GlooudsTools, id: 1 },
        { img: OpportunityMap, id: 2 },
        { img: Fan20, id: 3 },
        { img: DigitalAssets, id: 4 },
        { img: GlooudsMetaverse, id: 5 },
        { img: FIFAGloouds },
        // { img: Fan20Example },
        // { img: GlooudsPatronageExample },
        // { img: GlooudsMetaverseExample },
        // { img: GlooudsMonetizationExample },
        { img: GlooudsTeamBuilder },
        // { img: GlooudsMarketPotential },
        { img: GlooudsUtilityToken },
        // { img: GlooudsCurrentFunding },
        // { img: GlooudsPyL },
        // { img: GlooudsUseOfFunds },
        // { img: GlooudsPotentialValuation },
        // { img: GlooudsCurrentOwnership },
        // { img: GlooudsTeam },
        { img: GlooudsPartners},
      ];

    const sliderRef = React.useRef();

    const goToSlide = (id) => {
        
        const imageIndex = carouselImages.findIndex((image) => image.id === id);

        if(sliderRef.current){
            sliderRef.current.slickGoTo(imageIndex)
        }
    }

    const settings = {
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    // const redirectToNFT = () => {
    //     window.open('https://market.gloouds.com/')
    // }

  return (
    <div className="carouselGeneralContainer">
        <div className="buttonsContainer">
            {buttonNames?.map((data, index) => {
                return(
                    <button key={`button-${index}`} className='buttons' onClick={() => goToSlide(data.id)}> {data.name} </button>
                )
                })}
        </div>
        <Slider ref={sliderRef} {...settings} className='carousel'>
            {carouselImages?.map((data, index) => {
                return(
                    <div className='imagesContainer' key={`img-${index}`}>
                        <img src={data.img} alt={`Imagen ${index + 1}`} className='images'/>
                    </div>
                )
            })}
        </Slider>
    </div>
  )
}

export default Carousel