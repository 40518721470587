import "./Home.css";
// components
import Banner from "../../components/Banner/Banner";
// import ContainerCardsInfo from "../../components/ContainerCardsInfo/ContainerCardsInfo";
import VideoHome from "../../components/VideoHome/VideoHome";
// import ContainerButtonsBanner from "../../components/ContainerButtonsBanner/ContainerButtonsBanner";
// import ContainerModulesInfo from "../../components/ContainerModulesInfo/ContainerModulesInfo";
import Ambassadors from "../../components/Ambassadors/Ambassadors";
import Content from "../../components/Content/Content";
// import SectionButtons from "../../components/SectionButtons/SectionButtons";
import { useState } from "react";
import DialogCardsHome from "../../components/DialogCardsHome/DialogCardsHome";
import Imagen from "../../assets/Cafu.png";
import HowWeDoIt from "../../components/HowWeDoIt/HowWeDoIt";

const Home = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }

  const contentDialog = {
    img: Imagen,
    title: "GLOOUDS AMBASSADOR!",
    description: "I'm happy to join the solution. We will work to enhance the structures of youth football and its opportunities. Supporting the most beautiful sport in the world, which instills good habits and values that have a positive impact on the lives of millions of young people. With GLOOUDS, we add efficiency, transparency, and meritocracy to the processes of clubs and federations. We hope that young people continue to dream, learn, and grow through football."
  }

  return (
    <>
      {open === true ? (
        <DialogCardsHome
          open={open}
          onClose={handleClose}
          content={contentDialog}
        />
      ) : null}
      <Banner />
      <HowWeDoIt />
      <Ambassadors />
      <VideoHome />
      {/* <SectionButtons /> */}
      <Content />
      
    </>
  );
};

export default Home;
