import "./ProgramConditions.css";
import SDCLogo from "../../../assets/Partners/sdc.png";
import NXWLogo from "../../../assets/Partners/nxw.png";
import GPEXELogo from "../../../assets/Partners/gpexe.png";
import XaveLogo from "../../../assets/Partners/xave.png";
import WimsLogo from "../../../assets/Partners/wims.png";
import WfsLogo from "../../../assets/Partners/wfs.png";
import LbsportsLogo from "../../../assets/Partners/lbsports.png";
import FifaLogo from "../../../assets/Partners/fifa.png";
import y7Logo from "../../../assets/Partners/y7.png";

const ProgramConditions = () => {

  const partnersList = [
    {
      url: 'https://www.sportsdatacampus.com',
      logo: SDCLogo
    },
    {
      url: 'https://www.linkedin.com/company/nexworldsports/',
      logo: NXWLogo
    },
    {
      url: 'https://www.gpexe.com/',
      logo: GPEXELogo
    },
    {
      url: 'https://xave.world/',
      logo: XaveLogo
    },
    {
      url: 'https://y7capitalgroup.com/',
      logo: y7Logo
    },
    {
      url: 'https://wims.es/',
      logo: WimsLogo
    },
    {
      url: 'https://worldfootballsummit.com/en/',
      logo: WfsLogo
    },
    {
      url: 'https://www.instagram.com/asesorias.lbsports/',
      logo: LbsportsLogo
    },
    {
      url: 'https://www.fifa.com/es/social-impact/fifa-foundation/about-us',
      logo: FifaLogo
    },
  ]
  return (
    <>
      <div className="partners--container-content span1">
        <span></span>
        <div>
          <h3 style={{ color: "var(--cyan)" }}>
            {" "}
            Conditions to be a Certified Partner:
          </h3>
          <p>
            - Have an account registered in the Gloouds base <br />
            - Accept the general conditions (a PDF that can be consulted) <br />
            - To certify BRONZE: <br />
            - Have a person certified as GCAU <br />
            - No monthly or anual selling commitment <br />
            - To certify SILVER: <br />
            - Have one person certified GCAU and another as GCSM <br />
            - Sell 500 monthly licenses or 6000 per year <br />
            - To certify GOLD: <br />
            - Have three certified personnel, one as GCAU, one as GCDU and one as GCNT <br />
            - Sell 2,000 monthly licenses or 24,000 per year <br />
            - To certify PLATINUM: <br />
            - Have the three GOLD people plus a GCFS to attend channels <br />
            - Sell 4,000 monthly licenses or 48,000 per year <br />
            - Must have at least one GOLD subchannel <br />
            - The courses are valid for 24 months, after which they must be
            recertified. <br />
          </p>
        </div>

        <div>
          <div className="span2">
            <span></span>
          </div>
          <h3 style={{ color: "var(--cyan)" }}> Status Changes</h3>
          <p>
            - If the 24 months have passed and the exams are not taken again, the certification is lost. <br />
            - If it is PLATINUM, for a month selling less than 4,000 licenses but sells more than 500, it becomes GOLD. <br />
            - If it is GOLD and in a month sells less than 2,000 licenses but sells 500 or more, it becomes SILVER. If sells less than 500, it becomes BRONZE. <br />
            - If it is SILVER but in a month you stop selling 500 licenses and sells 500 or more, it becomes BRONZE. <br />
          </p>
        </div>
      </div>
      <div className="partners--container-content span2">
        <span></span>
        <div>
          <h3 style={{ color: "var(--cyan)" }}>
            {" "}
            Conditions to be Gloouds Learning Partner (GLP)
          </h3>
          <p>
            - Have an account registered in the Gloouds database <br />
            - Accept the general conditions (a PDF that can be visualized) <br />
            - The future instructor must be GCFS (Gloouds Certified Full Stak) and pass the GICP (Gloouds Instructor Certification Program) <br />
            - The courses will require an evaluation by the students. <br />
          </p>
        </div>
        <div>
          <h3 style={{ color: "var(--cyan)" }}> Status changes </h3>
          <p>
            - If the 24 months have passed and the exams have not been taken
            again, the certification is lost. <br />- If the average evaluation
            by Q is less than 4.25/5, the certification as GLP will be lost.
          </p>
        </div>
      </div>
      <div className="partners--container-agreement">
        <h6>
          Also, must sign the &quot;Partnership Agreement&quot; which is a PDF
          that can be consulted.
        </h6>
        <h3>Global Price List</h3>
        <p>
          <a href="https://gloouds-resource-v2.s3.amazonaws.com/Public/GPL_220721.xlsx" target="_blank" rel="noreferrer"> Click here to download the Global Price List</a>
        </p>
        <h3>Our partners</h3>
        <div>
          <p>Find a partner in your country:</p>
          <div className="tableContainer">
            <div className="partnerContainer">
              {partnersList.map((partner, index) => {
                return (
                  <div className="partnerLogo" key={index}>
                  <a href={partner.url} target="_blank" rel="noreferrer noopener" >
                    <img src={partner.logo}></img>
                  </a>
                </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProgramConditions;
