import { useState } from 'react';
import styles from './DeleteAccount.module.css';
import { verifyUser } from '../../services/api';
import { sendEmail } from '../../services/email';
import { useNavigate } from 'react-router-dom';

const DeleteAccount = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);
    const [cause, setCause] = useState('')
    const navigate = useNavigate()
    
    const selectCause = () => {
        let text = ''
        switch(cause) {
            case '':
                text = 'El usuario no especificó un motivo'
                break
            case 'precio':
                text = 'GLOOUDS es demasiado caro para mi'
                break
            case 'uso':
                text = 'Hace mucho tiempo que no lo uso'
                break
            case 'resultados':
                text = 'No me brinda los resultados que esperaba'
                break
            case 'prueba':
                text = 'Lo contraté para probarlo y no me interesa continuar usandolo'
                break
            case 'otro':
                text = 'Otros motivos'
                break
        }
    return text;
    }

    const emailData = {
        subject: "Quiero eliiminar mi cuenta de GLOOUDS",
        to: "hola@gloouds.com",
        message: `Soy ${email} y quiero eliminar mi cuenta de gloouds. Motivo: ${selectCause()}`,
        template: ''
    }

    const submitData = () => {
        setError(false)
        console.log('accept')
        verifyUser(email, password)
        .then((res) => {
            console.log("response", res)
            if (res === 'error') {
                setError(true)
            } else {
                console.log('enviar mail')
                sendEmail(emailData, 'ok')
                .then((response) => {
                    console.log(response)
                    if (response === 'ok') {
                        navigate('/')
                    }
                })
            }
        })
    }

    return (
        <div className={styles.container}>
            <h1>Eliminación de usuario</h1>
            <h3>Al completar este formulario y enviarlo usted estará solicitando la elimination de su usuario</h3>
            <p>Una vez analizado y de ser factible la eliminación se le notificará acerca del resultado de la eliminación</p>
            <p>Al eliminarse la cuenta se elimnaran los datos generados por ti, pero los datos generados por el club serguiran en nuestra base</p>
            <div className={styles.form}>
                <div className={styles.inputLine} style={{marginTop: '4rem'}}>
                    <label>Ingrese su usuario</label>
                    <input 
                        type='text'
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                </div>
                <div className={styles.inputLine}>
                    <label>Ingrese su contraseña</label>
                    <input 
                        type='password'
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                </div>
                <div style={{color: 'red', visibility: error ? 'visible' : 'hidden', marginBottom: '2rem'}}>Error en usuario o contraseña</div>
                <div className={styles.form}>
                    <h3>Podrías indicarnos el motivo de tu pedido de baja?</h3>
                    <div className={styles.inputLine}>
                        <p>GLOOUDS es demasiado caro para mi</p>
                        <input 
                            type='radio'
                            name='cause'
                            value='precio'
                            checked={cause === 'precio'}
                            onChange={(e)=>setCause(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputLine}>
                        <p>Hace mucho tiempo que no lo uso</p>
                        <input 
                            type='radio'
                            name='cause'
                            value='uso'
                            checked={cause === 'uso'}
                            onChange={(e)=>setCause(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputLine}>
                        <p>No me brinda los resultados que esperaba</p>
                        <input 
                            type='radio'
                            name='cause'
                            value='resultados'
                            checked={cause === 'resultados'}
                            onChange={(e)=>setCause(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputLine}>
                        <p>Lo contraté para probarlo y no me interesa continuar usandolo</p>
                        <input 
                            type='radio'
                            name='cause'
                            value='prueba'
                            checked={cause === 'prueba'}
                            onChange={(e)=>setCause(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputLine}>
                        <p>Otro motivo</p>
                        <input 
                            type='radio'
                            name='cause'
                            value='otro'
                            checked={cause === 'otro'}
                            onChange={(e)=>setCause(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.buttonDiv} onClick={submitData}>Aceptar</div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount;